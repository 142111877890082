<template>
  <div>
    <portal to="title-page">
      <h1>Editar Transporte</h1>
    </portal>
    <div>
      <v-card class="mx-auto elevation-1">
        <v-card-title>
          <v-icon large left>{{mdiPencil}}</v-icon>
          <span class="title font-weight-light">Datos</span>
          <v-spacer></v-spacer>
          <v-btn small class="ml-2" @click="$router.go(-1)">
            <v-icon>{{mdiArrowLeft}}</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" v-model="validForm" lazy-validation>
            <!-- Type transport -->
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="transport.name"
                  :prepend-icon="mdiPackageVariantClosed"
                  :rules="[v => !!v || 'Elige el tour para el transporte']"
                  label="Tour"
                  disabled="disabled"
                  required
                  dense
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Numero minimo de pax para ofrecer servicio colectivo"
                  :prepend-icon="mdiAccount"
                  type="number"
                  color="primary"
                  v-model="transport.minPaxShared"
                  required
                  :rules="[rules.required]"
                  hint="Numero minimo de personas para ofrecer servicio colectivo"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-text-field
                  label="Capacidad total de personas en una camioneta "
                  :prepend-icon="mdiAccount"
                  type="number"
                  color="primary"
                  v-model="transport.maxPaxPrivate"
                  required
                  hint="Sirve para determinar cuantas vanes se usaran para el servicio"
                  :rules="[rules.required]"
                  dense
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="12">
                <v-select
                  v-model="transport.transport"
                  :items="selectTransport"
                  :prepend-icon="mdiTrainCar "
                  item-text="text"
                  item-value="value"
                  :rules="[v => !!v || 'Elige el tipo de transporte']"
                  label="¿Incluye transporte?"
                  required
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  :disabled="!validForm || this.transport.minPaxShared == '' || this.transport.maxPaxPrivate =='' || this.transport.transport == '' "
                  color="primary"
                  class="mr-5"
                  @click="validateForm"
                >Agregar</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </div>
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" :timeout="-1">
      {{snackbar.message}}
      <template v-slot:action="{ attrs }">
        <v-btn color text v-bind="attrs" @click="snackbar.show = false">
          <v-icon>{{mdiClose}}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import {
  mdiTrainCar,
  mdiCurrencyUsd,
  mdiMapMarker,
  mdiPackageVariantClosed,
  mdiAccount,
  mdiClose,
  mdiPencil,
  mdiArrowLeft,
} from "@mdi/js";
import Transporte from "@/models/Transport.js";
export default {
  name: "TransportCreate",
  data() {
    return {
      /******* icons ***********/
      mdiClose: mdiClose,
      mdiPackageVariantClosed: mdiPackageVariantClosed,
      mdiTrainCar: mdiTrainCar,
      mdiMapMarker: mdiMapMarker,
      mdiAccount: mdiAccount,
      mdiCurrencyUsd: mdiCurrencyUsd,
      mdiPencil: mdiPencil,
      mdiArrowLeft:mdiArrowLeft,
      /***********************/
      TransportModel: new Transporte(),
      /******  data v-model transport  *******/
      transport: [],
      /*****  data v-model of rute collections   *******/
      //tour: [],
      tourId: null,
      // variable of gets zone and tour (mounted)
      toursData: [],
      /******   change variables   ***********/
      changeTour: null,
      rules: {
        required: (v) => (v && v.length > 0) || "Campo requerido",
        min: (v) => v.length >= 4 || "Min 4 caracteres",
      },
      selectTransport: [
        { value: true, text: "Si" },
        { value: false, text: "No" },
      ],
      validForm: true,
      snackbar: {
        show: false,
        color: "",
        message: "",
      },
      caseShared: "shared",
      casePrivate: "private",
      changeTypeTransport: null,
    };
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        let loading = this.$loading.show();
        var docDataTransport = {
          minPaxShared: this.transport.minPaxShared,
          maxPaxPrivate: this.transport.maxPaxPrivate,
          transport: this.transport.transport,
        };
        this.TransportModel.add(this.tourId, docDataTransport)
          .then(() => {
            loading.hide();
            this.snackbar.show = true;
            this.snackbar.color = "green";
            this.snackbar.message = `La tarifa del transporte se agrego correctamente `;
            /******  Limpiar variables  *******/
            this.$router.push({ path: "/backend/admin/transport" });
          })
          .catch((error) => {
            console.log(error);
            this.snackbar.show = true;
            this.snackbar.color = "red";
            this.snackbar.message =
              "Error no se guardo el cupon, por favor contacte al Administrador si el problema persiste";
          });
      }
    },
    showSnackbarError(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "red";
    },
    showSnackbarSuccess(message) {
      this.snackbar.show = true;
      this.snackbar.message = message;
      this.snackbar.color = "green";
    },
  },

  mounted() {
    // get data of tours
    this.tourId = this.$route.params.Id;
    console.log(this.tourId);
    this.TransportModel.findById(this.tourId).onSnapshot((doc) => {
      this.transport = doc.data();
      //this.transport. = doc.data();
    });
  },
  created() {
    this.$store.commit("SET_LAYOUT", "admin");
  },
};
</script>
